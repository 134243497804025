import { signInWithPopup } from "firebase/auth";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth, Providers } from "../config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useState } from "react";


const useUserEmail = () => {
  const [userEmail, setUserEmail] = useState();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUserEmail(user.email);
    } else {
      setUserEmail(null);
    }
  });

  return userEmail;
};


const useLogin = (redirect: string) => {
  const navigate = useNavigate();

  const loginFunction = () => {
    signInWithPopup(auth, Providers.google)
        .then(() => { navigate(0); })
        .catch((error) => { console.error(error); });
  };

  return loginFunction;
};


const useLogout = (redirect: string) => {
  const navigate = useNavigate();

  const logoutFunction = () => {
    signOut(auth)
        .then(() => { navigate(0); })
        .catch((error) => { console.error(error); });
  };

  return logoutFunction;
};


export { useLogin, useLogout, useUserEmail };

