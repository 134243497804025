import { useState, useEffect } from "react";
import tableWithAuth from "../model/Airtable";
import { getAuth, getIdToken } from "firebase/auth";



// Airtable data is memoized here the first time it is requested.
const memo = {}; // Tracks response values.
const req = {}; // Tracks in-flight requests.

const useBase = (baseName) => {
  const [idToken, setIdToken] = useState();
  const [data, setData] = useState([]);
  const isInFlight = req[baseName];

  useEffect(() => {
    const authAsync = async () => {
      const auth = getAuth();
      const { currentUser } = auth;
      const token = await getIdToken(currentUser, true);
      setIdToken(token);
      return token;
    };

    if (memo[baseName]) {
      setData(memo[baseName]);
      return;
    }

    if (isInFlight) {
      // Request in flight, but we didn't initiate it.
      // The goal is to de-dupe HTTP requests, and instead wait for the
      // response to an identical request currently in-flight. There's probably
      // a more React-y way to do this.
      let interval = setInterval(() => {
        if (!isInFlight) {
          setData(memo[baseName]);
          clearInterval(interval);
        }
      }, 100);
      return;
    }

    req[baseName] = true;

    const sendRequestAsync = async () => {
      const auth = idToken || await authAsync();
      tableWithAuth(auth, baseName)
        .select({ view: "Grid view" })
        .eachPage(
          (records, next) => {
            memo[baseName] = (memo[baseName] || []).concat(records);
            setData(memo[baseName]);
            req[baseName] = false;
            next();
          },
          (err) => {}
        )
    };
    sendRequestAsync();

  }, [baseName, isInFlight, idToken]);

  return data;
};

const useRows = (baseName, recIds) => {
  const data = useBase(baseName);

  if (!recIds || !data) {
    return [];
  }

  return data
    .filter((item) => recIds.indexOf(item.id) !== -1)
    .map((r) => r.fields);
};

const useFields = (baseName, fieldName, recIds) => {
  const data = useRows(baseName, recIds);
  return data.map((item) => item[fieldName]);
};

export {
  useBase,
  useRows,
  useFields,
};
