import { useBase } from "./airtableHooks";

const useVentures = () => useBase("Ventures").map((r) => r.fields);

const useEntries = () => useBase("Entries").map((r) => r.fields);

export {
  useEntries,
  useVentures,
};
