import Box from '@mui/material/Box';
import { ReactNode } from "react";


interface Props {
  noPadding?: boolean,
  children?: ReactNode,
  sx?: any,
}


const Section = ({noPadding, children, sx}: Props) => {
  return (
    <Box sx={{
      ...sx,
      p: noPadding ? 0 : 6,
      mx: 'auto',
    }}>
      {children}
    </Box>
  );
};


export default Section;
