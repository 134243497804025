import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'


interface Props {
  text: string,
  url: string,
}


const ExternalLink = ({ text, url }: Props) => {
  const onClick = () => { window.open(url, '_blank'); };

  return (
    <div>
      <button onClick={onClick} className="inline-flex items-center px-6 py-3 rounded-lg bg-yellow-400 text-gray-900">
        <FontAwesomeIcon icon={faArrowRight} />
        <span className="flex flex-col items-start ml-4 leading-none">
          <span className="font-semibold title-font">{text}</span>
        </span>
      </button>
    </div>
  );
};


export default ExternalLink;

