import { useEffect, useState } from "react";

const useTimer = (milliseconds: int) => {
  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    if (isFinished) {
      return;
    }
    const timerId = setTimeout(() => {
      setIsFinished(true);
    }, milliseconds);
    return (() => clearTimeout(timerId));
  }, [milliseconds, isFinished]);

  return isFinished;
};

export { useTimer };
