import Carousel from "../components/common/Carousel";
import ExternalLink from "../components/ExternalLink";
import Video from "../components/common/Video";
import Header from "../components/common/Header";
import Section from "../components/common/Section";
import logo from "../image/logo.png";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useEntries, useVentures } from "../hook/galleryHooks";
import { useParams } from "react-router-dom";
import { useTimer } from "../hook/useTimer";


const Entry = () => {

  const ventures = useVentures();
  const entries = useEntries();
  const [entry, setEntry] = useState();
  const [venture, setVenture] = useState();
  const { name } = useParams();
  const timerExpired = useTimer(2500 /* milliseconds */);

  const wrap = (content: any) => (
    <div style={{ backgroundColor: "#f9fafb" }}>
      <Helmet>
        <title>Moon Gallery</title>
      </Helmet>
      <Header title="Moon Gallery" image={logo} items={[]} />
      {content}
    </div>
  );

  useEffect(() => {
    setEntry((entries || []).find(entry => entry["Key"] === name));
  }, [entries, name]);

  useEffect(() => {
    // TODO need the next layer down of information to do the matching.
    setVenture((ventures || []).find(venture => false));
  }, [entry, ventures]);

  if (!entry) {
    const content = timerExpired ? "Not found" : "...";
    return wrap(<Section><p>{content}</p></Section>);
  }

  return wrap(
    <Section>
      <div className="">
        <div className="flex">
          <h1 className="text-4xl font-sans font-bold leading-none sm:text-5xl flex-grow">
            {entry["Name"]}
          </h1>
          {entry["Main URL"] && <ExternalLink text="View site" url={entry["Main URL"]} />}
        </div>
        <div className="font-sans font-bold mb-2 text-yellow-400">{entry["Venture Text"]}</div>
        <div className="flex items-center content-center mb-8">
          {entry["Video"] ?
              <Video url={entry["Video"][0]["url"]} /> :
              <Carousel images={entry["Photos"]} />}
        </div>
        <p className="font-serif text-2xl">{entry["Description"]}</p>
        {/*
        <div>
          {JSON.stringify(entry)}
        </div>
        <div>
          {JSON.stringify(ventures)}
        </div>
        */}
      </div>
    </Section>
  );
};

export default Entry;

