import Airtable from "airtable";

const baseId = "app"; // Overridden server-side
const endpointUrl = "/api/airtable";

const tableWithAuth = (apiKey, tableName) => {
  Airtable.configure({ apiKey, endpointUrl });
  return Airtable.base(baseId)(tableName);
};

export default tableWithAuth;

