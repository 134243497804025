
interface Props {
  image?: any,
  url: string,
  title: string,
  titleEmphasis?: string,
  description: string,
  chips?: string[],
}

const Card = ({ image, title, titleEmphasis, description, chips }: Props) => {
  return (
    <div className="rounded overflow-hidden shadow-lg ease-out duration-300 hover:-translate-y-4">
      <img
        className="w-full object-cover h-56"
        src={image}
        alt="Backsplash"
      />
      <div className="px-6 py-4">
        {titleEmphasis &&
            <div className="font-bold font-sans mb-2 text-yellow-400">{titleEmphasis}</div>}
        {title &&
            <div className="font-bold font-sans text-xl mb-2">{title}</div>}
        {description &&
            <p className="text-gray-700 font-serif text-xl text-ellipsis line-clamp-2">
              {description}
            </p>}
      </div>
      <div className="px-6 pt-4 pb-2">
        {(chips || []).map((chip) => (
            <span
                key={chip}
                className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
              {chip}
            </span>
          ))}
      </div>
    </div>
  );
};

export default Card;
