import { Helmet } from "react-helmet-async";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useEntries, /* useVentures */ } from "../hook/galleryHooks";
import { useParams } from "react-router-dom";
import { useTimer } from "../hook/useTimer";
import Section from "../components/common/Section";
import EntryCard from "../components/EntryCard";


const Test = () => {

  // const ventures = useVentures();
  const entries = useEntries();
  const [entry, setEntry] = useState();
  const { name } = useParams();
  const timerExpired = useTimer(2500 /* milliseconds */);
  const navigate = useNavigate();

  useEffect(() => {
    setEntry((entries || []).find(entry => entry["Key"] === name));
  }, [entries, name]);

  if (!entry && timerExpired) {
    return (
      <div style={{ backgroundColor: "#f9fafb" }}>
        <Helmet>
          <title>Moon Gallery</title>
        </Helmet>
        <Section>
          <p>Not found</p>
        </Section>
      </div>
    );
  }

  if (!entry && !timerExpired) {
    return (
      <div style={{ backgroundColor: "#f9fafb" }}>
        <Helmet>
          <title>Moon Gallery</title>
        </Helmet>
        <Section>
          <p>...</p>
        </Section>
      </div>
    );
  }

  if (!entry) return;

  return (
    <div style={{ backgroundColor: "#f9fafb" }}>
      <Helmet>
        <title>{entry["Name"]} - Moon Gallery</title>
      </Helmet>
      <Section>
        <div onClick={navigate.bind(this, -1)}>Back</div>
        <p>{entry["Name"]}</p>
      </Section>
      <Section>
        <div className="grid gap-8 grid-col-1 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4">
          <div key={entry["Key"]}>
            <NavLink to={`/e/${entry["Key"]}`}>
              <EntryCard entry={entry} />
            </NavLink>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default Test;
