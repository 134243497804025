import { useState } from 'react';


interface Image {
  url: string,
  title?: string,
}

interface Props {
  images: Image[],
}


const Carousel = ({ images }: Props) => {
  const [index, setIndex] = useState(0);
  const image = images[index];

  const onPrev = () => setIndex(
      index => ((index + images.length - 1) % images.length));
  const onNext = () => setIndex(
      index => ((index + images.length + 1) % images.length));

  const prevArrow = (
    <button aria-label="Previous" onClick={onPrev} className="absolute left-0 z-30 p-2 ml-10 focus:outline-none focus:dark:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400">
      <svg width="8" height="14" fill="none" viewBox="0 0 8 14" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4">
        <path d="M7 1L1 7L7 13" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
      </svg>
    </button>
  );

  const nextArrow = (
    <button aria-label="Next" onClick={onNext} className="absolute right-0 z-30 p-2 mr-10 focus:outline-none focus:dark:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400">
      <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4">
        <path d="M1 1L7 7L1 13" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
      </svg>
    </button>
  );

  return (
    <div className="relative flex items-center justify-center w-full dark:text-gray-50">
      {images.length > 1 && prevArrow}
      <div className="relative flex flex-shrink-0 sm:w-auto">
        <img className="object-cover object-center h-96 aspect-square dark:bg-gray-500" src={image.url} alt={image.title} />
      </div>
      {images.length > 1 && nextArrow}
    </div>
  );
};

export default Carousel;

