import { NavLink } from "react-router-dom";
import { useUserEmail, useLogin, useLogout } from "../../hook/useAuth";


// @ref https://mambaui.com/components/header


interface Props {
	image?: any,
	title: string,
  items: NavItem[],
}


interface NavItem {
  label: string,
  active?: boolean,
  href?: string,
}


const classes = {
  navItemActive: "flex items-center px-4 -mb-1 border-b-2 dark:border-transparent dark:text-violet-400 dark:border-violet-400",
  navItemInactive: "flex items-center px-4 -mb-1 border-b-2 dark:border-transparent",
  login: "flex items-center px-4 -mb-1 border-b-2 dark:border-transparent cursor-pointer",
  title: "flex items-center px-4 -mb-1 border-b-2 dark:border-transparent text-xl font-bold ml-8",
};


const Header = ({ image, title, items }: Props) => {
  const userEmail = useUserEmail();
  const loginFn = useLogin("/");
  const logoutFn = useLogout("/");

	return (
    <header className="p-4 dark:bg-gray-800 dark:text-gray-100 sticky top-0 z-50 font-sans">
      <div className="container flex justify-between h-16 mx-auto">
        <div className="container flex">
          <NavLink rel="noopener noreferrer" to="/" aria-label="Home" className="flex items-center p-2">
            <img src={image} className="object-contain h-20 w-20" alt="Logo" />
          </NavLink>
          <div className={classes["title"]}>
            {title}
          </div>
        </div>
        <ul className="items-stretch hidden space-x-3 md:flex">
          {items.map((item, i) => (
            <li className="flex" key={i}>
              <a rel="noopener noreferrer" href={item.href || "#"} className={classes[item.active ? "navItemActive" : "navItemInactive"]}>
                {item.label}
              </a>
            </li>
          ))}
          <li className="flex">
            <div onClick={() => {userEmail ? logoutFn() : loginFn()}} className={classes["login"]}>
              {userEmail || "Log in"}
            </div>
          </li>
        </ul>
        <button className="flex justify-end p-4 md:hidden">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </button>
      </div>
    </header>

	);
};


export default Header;
