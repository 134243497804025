import { useEntries } from "../hook/galleryHooks";


const Home = () => {

  const entries = useEntries();

  return (
    <>
      <div>
        {entries.map((e) => <p>{e["Name"]}</p>)}
      </div>
    </>
  );
};

export default Home;

