import Card from "./common/Card";


interface Props {
  entry?: any
}

const EntryCard = ({ entry }: Props) => {
  return (
    <Card 
        title={entry["Name"]}
        titleEmphasis={entry["Venture Text"]}
        image={entry["Photos"][0]["url"]}
        url={'/'}
        description={entry["Description"]}
        />
  );
};

export default EntryCard;
