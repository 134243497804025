import Entry from "../screens/Entry";
import Home from "../screens/Home";
import Landing from "../screens/Landing";
import Login from "../screens/Login";
import Test from "../screens/Test";

interface RouteType {
  path: string;
  component: any;
  name: string;
  protected: boolean;
}

const routes: RouteType[] = [
  {
    path: "",
    component: Landing,
    name: "Landing Screen",
    protected: true,
  },
  {
    path: "/login",
    component: Login,
    name: "Login Screen",
    protected: false,
  },
  {
    path: "/home",
    component: Home,
    name: "Gallery Home",
    protected: true,
  },
  {
    path: "/e/:name",
    component: Entry,
    name: "Detail",
    protected: true,
  },
  {
    path: "/test",
    component: Test,
    name: "Test Screen",
    protected: true,
  },
];

export default routes;
