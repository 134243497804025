import ReactPlayer from 'react-player'


interface Props {
  url: string,
}


const Video = ({ url }: Props) => {
  return (
    <div className="relative flex items-center justify-center w-full">
      <ReactPlayer url={url} controls={true} />
    </div>
  );
};

export default Video;

