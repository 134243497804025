import EntryCard from "../components/EntryCard";
import Header from "../components/common/Header";
import Section from "../components/common/Section";
import logo from "../image/logo.png";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import { useEntries, /* useVentures */ } from "../hook/galleryHooks";


const Home = () => {
  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  // const ventures = useVentures();
  const entries = useEntries();

  return (
    <div style={{ backgroundColor: "#f9fafb" }}>
      <Helmet>
        <title>Moon Gallery</title>
      </Helmet>
      <Header title="Moon Gallery" image={logo} items={[]} />
      <Section>
        <div className="grid gap-8 grid-col-1 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4">
          {entries.map((entry) => (
            <div key={entry["Key"]}>
              {console.log(entry)}
              <NavLink to={`/e/${entry["Key"]}`}>
                <EntryCard entry={entry} />
              </NavLink>
            </div>
          ))}
        </div>
      </Section>
    </div>
  );
};

export default Home;
